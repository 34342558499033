import React from 'react'

import { graphql, useFragment } from 'react-relay'

import type { SeoProps } from './SeoWrapper'
import SeoWrapper from './SeoWrapper'

import type { PlanLayout_viewer$key } from '__generated__/PlanLayout_viewer.graphql'
import Column from 'components/Column'
import Columns from 'components/Columns'
import Container from 'components/Container'
import Hide from 'components/Hide'
import { MembershipCardNameProvider } from 'components/MembershipCard/Hooks/MembershipCardName'
import Box from 'components/Primitives/Box'
import PlansCheckoutDetail from 'containers/PlansCheckout/PlansCheckoutDetail/PlansCheckoutDetail'
import type { Step } from 'modules/Checkout/CheckoutFormProgressContainer'
import CheckoutFormProgressContainer from 'modules/Checkout/CheckoutFormProgressContainer'
import { zIndex } from 'theme'

type LayoutProps = {
    step: Step
    formComponent: React.ReactNode
    viewer: PlanLayout_viewer$key
    seoProps: SeoProps
    showPricingDetail: boolean
}

const PlansLayout = ({
    step,
    formComponent,
    seoProps,
    viewer: viewerKey,
    showPricingDetail,
}: LayoutProps) => {
    const viewer = useFragment(PlansLayoutFragment, viewerKey)
    const RightSidePlansPanel = () => (
        <Box
            display="flex"
            minHeight={[null, null, null, '100vh']}
            alignItems="center"
            flexDirection="column"
            position="relative"
            pt={[1, 1, 3, 5]}
        >
            <PlansCheckoutDetail viewer={viewer} showPricingDetail={showPricingDetail} />
        </Box>
    )
    return (
        <SeoWrapper seoProps={seoProps}>
            <MembershipCardNameProvider>
                <Container minHeight="100%" isFluid>
                    <Columns display="flex" flexWrap="nowrap" height="100%">
                        <Column
                            bg="white"
                            spread={[12, 12, 12, 6, 5]}
                            boxShadow="0px 0px 12px 0px #0000001F;"
                            zIndex={zIndex.layer05}
                            minHeight="100%"
                        >
                            <CheckoutFormProgressContainer currentStep={step}>
                                {formComponent}
                                <Hide on={[false, false, false, true, true]}>
                                    <Column flex={1} height="100%">
                                        <RightSidePlansPanel />
                                    </Column>
                                </Hide>
                            </CheckoutFormProgressContainer>
                        </Column>

                        <Hide on={[true, true, true, false, false]} flex={1}>
                            <Column flex={1} height="100%">
                                <RightSidePlansPanel />
                            </Column>
                        </Hide>
                    </Columns>
                </Container>
            </MembershipCardNameProvider>
        </SeoWrapper>
    )
}

const PlansLayoutFragment = graphql`
    fragment PlanLayout_viewer on Viewer @argumentDefinitions(slug: { type: "String" }) {
        ...PlansCheckoutDetail_viewer @arguments(slug: $slug)
    }
`

export default PlansLayout
