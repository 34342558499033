import { useMemo } from 'react'

import { graphql, createFragmentContainer } from 'react-relay'

import DiscountBanner from './DiscountBanner'
import PriceAndDiscount from './PriceAndDiscount'

import type { PlansCheckoutDetail_viewer } from '__generated__/PlansCheckoutDetail_viewer.graphql'
import MembershipCard from 'components/MembershipCard'
import { cssCardAnimation } from 'components/MembershipCard/animations'
import { getSignUpNameFieldValue } from 'components/MembershipCard/Hooks/MembershipCardName'
import { CARD_BORDER_RADIUS } from 'components/MembershipCard/index'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import createPriceString, { getPlanCheckoutSmallPrint } from 'lib/createPriceString'
import { fonts } from 'theme'
import { isAnnual } from 'types/stripeConsts'

type Props = {
    encryptedEmail?: string
    viewer: PlansCheckoutDetail_viewer
    showPricingDetail: boolean
}

export const PRICING_DETAIL_TITLE = 'Finimize Premium'

export const PlansCheckoutDetail = ({ viewer, showPricingDetail }: Props) => {
    const { webPaymentPlan, me } = viewer

    // Pass in name from serverside (for checkout) or call on name in constate (for sign up)
    const savedName = useMemo(() => me?.firstName?.toUpperCase(), [me?.firstName])
    const valueInFirstNameField = getSignUpNameFieldValue()
    const displayedName = savedName || valueInFirstNameField

    const { promotionApplied, interval, currency, currencySymbol, amount } = webPaymentPlan

    const promotionStripeDiscount = promotionApplied?.stripeDiscount
    const promotionTrialDays = promotionApplied?.trialDays || null
    const couponDurationInMonths = promotionApplied?.stripeDiscount.couponDurationInMonths || null
    const discountPct = promotionStripeDiscount?.discountPct || null
    const discountAmount = promotionStripeDiscount?.discountAmount || null

    const isAnnualPlan = !!interval && isAnnual(interval.toLowerCase())

    const calculatedMonthlyPrice = createPriceString({
        // as we're showing this per month
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
        convertToMonth: isAnnualPlan,
        hideCurrencyInfo: true,
    })

    const calculatedPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
        hideCurrencyInfo: true,
    })

    const noDiscountPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const displayedDiscountAmount = createPriceString({
        amount: discountAmount ? discountAmount / 100 : 0,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const smallPrint = getPlanCheckoutSmallPrint(
        isAnnualPlan,
        promotionTrialDays,
        calculatedPrice,
        calculatedMonthlyPrice,
        discountPct,
        discountAmount,
        currency,
        currencySymbol,
        amount,
        couponDurationInMonths,
    )

    const borderTitleFontSize = 13
    const borderTitleHoriztonalPadding = 10
    const borderTitleVerticalOffset = (borderTitleHoriztonalPadding + borderTitleFontSize) / -2

    return (
        <Box ml={[null, null, null, 3]}>
            <Box
                borderRadius={CARD_BORDER_RADIUS}
                width="max-content"
                borderColor="neutral.300"
                border="3px dotted"
                position="relative"
                display={['none', 'none', 'none', 'flex']}
                p={3}
                mb={3}
            >
                <Box
                    textAlign="center"
                    position="absolute"
                    width="200px"
                    top={`${borderTitleVerticalOffset}px`}
                    backgroundColor="neutral.0"
                    px={`${borderTitleHoriztonalPadding}px`}
                    fontSize={`${borderTitleFontSize}px`}
                    fontWeight={'500'}
                    color="neutral.400"
                >
                    Your Membership Card
                </Box>
                <MembershipCard cssAnimation={cssCardAnimation} name={displayedName} />
            </Box>

            {showPricingDetail && (
                <Box
                    textAlign={['center', 'center', 'center', 'left']}
                    display={['flex', 'flex', 'flex', 'inherit']}
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box fontWeight={fonts.weight.bold} fontSize="1.5rem" mb="1rem">
                        {PRICING_DETAIL_TITLE}
                    </Box>

                    <PriceAndDiscount
                        price={noDiscountPrice}
                        discountPrice={calculatedPrice}
                        discountPct={discountPct}
                        isDiscountAbs={discountAmount}
                        discountAbsDisplay={displayedDiscountAmount}
                        trialDays={promotionTrialDays}
                        currencySymbol={webPaymentPlan.currencySymbol}
                    />
                    <DiscountBanner
                        discountAmount={discountAmount}
                        discountPct={discountPct}
                        displayedDiscountAmount={displayedDiscountAmount}
                        promotionTrialDays={promotionTrialDays}
                    />
                    <Paragraph
                        color="neutral.999"
                        mt="1rem"
                        fontSize="12px"
                        fontWeight="400"
                        maxWidth="350px"
                    >
                        {smallPrint}
                    </Paragraph>
                </Box>
            )}
        </Box>
    )
}

export default createFragmentContainer(PlansCheckoutDetail, {
    viewer: graphql`
        fragment PlansCheckoutDetail_viewer on Viewer
        @argumentDefinitions(slug: { type: "String" }) {
            ...Experiments_viewer
            me {
                firstName
            }
            webPaymentPlan(productSlug: $slug) {
                id
                amount
                currency
                currencySymbol
                interval
                nickname
                promotionApplied(slug: $slug) {
                    trialDays
                    stripeDiscount {
                        discountPct
                        discountAmount
                        couponDurationInMonths
                        couponDurationInMonths
                    }
                }
            }
        }
    `,
})
